/* ---------- ICONS ---------- */

.material-icons {
  font-size: 15px;
  vertical-align: middle;
}

/* ---------- DEFAULTS ---------- */

body {
  color: #000000;
  font-family: 'Mulish', sans-serif;
  font-size: 16px;
  line-height: 1.2;
}
.row {
  margin: 0;
}
p {
  margin: 0;
}
a {
  color: #000000;
  text-decoration: none;
}
a:hover {
  color: #000000;
  text-decoration: underline;
}
h1 {
  font-size: 32px;
  margin: 0px 0px 50px 0px;
}
h2 {
  font-size: 22px;
}
input,
textarea,
select {
  background-color: transparent;
  border-color: #cccccc;
  border-radius: 0px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  color: #000000;
  padding: 15px 20px 15px 20px;
  width: 100%;
}
button {
  background-color: #000000;
  border-color: #000000;
  border-radius: 30px;
  border-style: solid;
  border-width: 2px;
  color: #ffffff;
  cursor: pointer;
  padding: 7.5px 20px 7.5px 20px;
}
button:hover {
  background-color: transparent;
  color: #000000;
}

/* ---------- GENERAL ---------- */

.float_right {
  float: right;
}
.padding_no {
  padding: 0 !important;
}
.success {
  background-color: #00fa9a;
  margin: 0px 0px 50px 0px;
  padding: 15px 20px 15px 20px;
}

.success p {
  margin: 0px 0px 0px 0px !important;
}

/* ---------- FORM ---------- */

form .field {
  margin: 0px 0px 30px 0px;
  position: relative;
}
form .field .label {
  background-color: #ffffff;
  color: #444444;
  font-size: 14px;
  padding: 0px 5px 0px 5px;
  position: absolute;
  top: -7.5px;
  left: 15px;
  z-index: 10;
}
form .field .count {
  font-size: 14px;
  position: absolute;
  top: 15px;
  right: 20px;
}
form .field .remove {
  position: absolute;
  top: 15px;
  right: 20px;
}
form .field .remove button {
  background-color: transparent;
  border-radius: 0px;
  border-width: 0px;
  color: #000000;
  padding: 0;
}
/* form .field .remove button:hover {
} */
form .field .visibility {
  position: absolute;
  top: 15px;
  right: 20px;
}
form .field .visibility button {
  background-color: transparent;
  border-radius: 0px;
  border-width: 0px;
  color: #000000;
  padding: 0;
}
/* form .field .visibility button:hover {
} */
form .field .validation {
  color: #ff0000;
  font-size: 14px;
  font-style: italic;
  margin: 5px 0px 0px 10px;
}
form .field .thumbnail {
  margin: 10px 0px 0px 0px;
  position: relative;
  width: 250px;
}
form .field .thumbnail .remove {
  position: absolute;
  top: 0px;
  right: -20px;
}
form .field .thumbnail .remove button {
  background-color: transparent;
  border-radius: 0px;
  border-width: 0px;
  color: #000000;
  padding: 0;
}
/* form .field .thumbnail .remove button:hover {
} */
form .field .thumbnail img {
  width: 100%;
}
form .add {
  margin: 0px 0px 30px 0px;
}
form .add button {
  background-color: transparent;
  border-color: #000000;
  color: #000000;
}
form .add button:hover {
  background-color: #000000;
  color: #ffffff;
}
form.tracking {
  margin: 30px 0px 0px 0px;
}
form .submit {
  margin: 20px 0px 0px 0px;
}

form .cancel {
  margin: 20px 0px 0px 0px;
}

form .cancel button {
  background-color: transparent;
  border-color: #000000;
  color: #000000;
}
form .cancel button:hover {
  background-color: #000000;
  color: #ffffff;
}
/* form .submit button {
}
form .submit button:hover {
} */

/* ---------- LOGIN ---------- */

.screen {
  background-color: #efefef;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.screen .box {
  background-color: #ffffff;
  padding: 40px 40px 40px 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
}

/* ---------- CONTAINERS ---------- */

.container,
.container-fluid {
  margin: 0;
  padding: 0;
}
.left {
  background-color: #efefef;
  height: 100%;
  padding: 20px;
}
.right {
  background-color: #ffffff;
  height: 100%;
  padding: 50px 80px 50px 80px;
}

/* ---------- LEFT ---------- */

.left .logo {
  margin: 0px 0px 30px 0px;
}
.left .logo img {
  width: 70%;
}
/* .left .link {
} */
.left .link button {
  background-color: transparent;
  border-width: 0px;
  color: #000000;
  text-align: left;
  width: 100%;
}
.left .link button:hover {
  background-color: #000000;
  color: #efefef;
}
.left .logout {
  margin: 30px 0px 0px 0px;
}
.left .logout button {
  background-color: transparent;
  border-width: 0px;
  color: #000000;
}
.left .logout button:hover {
  background-color: #000000;
  color: #efefef;
}

/* ---------- RIGHT ---------- */

.right .add {
  margin: 0px 0px 50px 0px;
}
.right .add button {
  background-color: transparent;
  border-color: #000000;
  color: #000000;
}
.right .add button:hover {
  background-color: #000000;
  color: #ffffff;
}
.right .item {
  background-color: #efefef;
  margin: 0px 0px 10px 0px;
}
.right .item .content {
  padding: 10px 0px 10px 10px;
}
.right .item .content .column {
  margin: 0px 20px 0px 0px;
}
.right .item .content .active {
  color: #00fa9a;
  margin: 0px 20px 0px 0px;
}
.right .item .content .unavailable {
  color: #dddddd;
  margin: 0px 20px 0px 0px;
}
.right .item .content .inactive {
  color: #f08080;
  margin: 0px 20px 0px 0px;
}
.right .item .content button.done {
  background-color: #00fa9a;
  border-radius: 5px;
  border-width: 0px;
  color: #000000;
  cursor: default;
  font-size: 10px;
  margin: 0px 20px 0px 0px;
  padding: 5px 10px 5px 10px;
}
.right .item .content button.pending {
  background-color: #dddddd;
  border-radius: 5px;
  border-width: 0px;
  color: #000000;
  cursor: default;
  font-size: 10px;
  margin: 0px 20px 0px 0px;
  padding: 5px 10px 5px 10px;
}
.right .item .content button.canceled {
  background-color: #f08080;
  border-radius: 5px;
  border-width: 0px;
  color: #000000;
  cursor: default;
  font-size: 10px;
  margin: 0px 20px 0px 0px;
  padding: 5px 10px 5px 10px;
}
.right .item .buttons {
  padding: 10px 10px 10px 10px;
  text-align: right;
}
.right .item .buttons button {
  background-color: transparent;
  border-radius: 0px;
  border-width: 0px;
  color: #000000;
  margin: 0px 0px 0px 10px;
  padding: 0;
}
/* .right .item .buttons button:hover {
} */
.right form {
  width: 70%;
}
.right h2 {
  margin: 30px 0px 0px 0px;
}
.right p {
  margin: 20px 0px 0px 0px;
}
.right p button.done {
  background-color: #00fa9a;
  border-radius: 5px;
  border-width: 0px;
  color: #000000;
  cursor: default;
  font-size: 10px;
  padding: 5px 10px 5px 10px;
}
.right p button.pending {
  background-color: #dddddd;
  border-radius: 5px;
  border-width: 0px;
  color: #000000;
  cursor: default;
  font-size: 10px;
  padding: 5px 10px 5px 10px;
}
.right p button.canceled {
  background-color: #f08080;
  border-radius: 5px;
  border-width: 0px;
  color: #000000;
  cursor: default;
  font-size: 10px;
  padding: 5px 10px 5px 10px;
}

/* ---------- POPUPS ---------- */

.grayout {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.grayout .popup {
  background-color: #ffffff;
  padding: 40px 40px 40px 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
}
.grayout .popup .close {
  float: right;
}
.grayout .popup .close button {
  background-color: transparent;
  border-radius: 0px;
  border-width: 0px;
  color: #000000;
  padding: 0;
}
/* .grayout .popup .close button:hover {
} */
.grayout .popup h1 {
  margin: 0px 0px 30px 0px;
}
.grayout .popup .buttons {
  margin: 30px 0px 0px 0px;
}
.grayout .popup .buttons button.cancel {
  background-color: transparent;
  border-color: #000000;
  color: #000000;
  margin: 0px 0px 0px 10px;
}
.grayout .popup .buttons button.cancel:hover {
  background-color: #000000;
  color: #ffffff;
}
